import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const getAllCountry = async() => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/agent/country',{headers:headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { getAllCountry };