import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteCustomer, retriveAllCustomers } from "../../services/CustomerService";

const CustomerList = () => {
    const [customers,setCustomers] = useState([]);
    const [page, setPage] = useState(1);
    const [links, setLinks] = useState([]);

    useEffect(()=>{
        getApprovedcustomer();
    },[page])

    const getApprovedcustomer = () =>{
        retriveAllCustomers(page)
            .then((response) => {
                if(response?.data)
                    setCustomers(response.data.data?.data);
                    setLinks(response.data.data?.links);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    const removeCustomer = (id) =>{
        deleteCustomer(id)
            .then((response) => {
                toast('Customer Deleted Successfully.',{type:'success'})
                let temp = customers.filter(c => c.id != id)
                setCustomers(temp);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Customers </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Listing </h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <thead>
                            <tr>
                                <th> Sr </th>
                                <th> POS Customer ID </th>
                                <th> Name </th>
                                <th> Email </th>
                                <th> Company </th>
                                <th> status </th>
                                <th> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customers.length > 0 ? 
                                    customers.map((customer,index) => {
                                        return (
                                            <tr key={index}>
                                                <td> { index + 1} </td>
                                                <td> { customer.pos_customer_id} </td>
                                                <td> { (customer.name_prefix ?? '') + " " + customer.name} </td>
                                                <td> { customer.email} </td>
                                                <td> { customer.company_name} </td>
                                                <td> <span class={"badge " + (customer.status=="approved"?'badge-success': customer.status=="rejected" ? 'badge-danger' : 'badge-info')}>{customer.status ?? 'Pending'}</span> </td>
                                                <td> 
                                                    <Link className="btn btn-warning" to={"/customer/edit/" + customer.id}> Edit </Link> &nbsp; 
                                                    <button type="button" className="btn btn-danger" onClick={()=> removeCustomer(customer.id)}> Delete </button> 
                                                </td>
                                            </tr>
                                        );
                                    }) 
                                : <tr>
                                    <td colSpan={6} className="empty-customers"> No Data Available. </td>
                                </tr>
                            }
                            </tbody>
                       </table>
                       <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                            {
                                links.map((link,index) => {
                                    return (
                                        <li key={"a" +index} className={"page-item" + (link.active ? ' active' : '')}>
                                            <a className={"page-link" + (!link.url ? ' a-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); if(link.url) {setPage(link.url?.split('page=')[1])}}} >{link.label.replace(/&laquo;/g, "<<").replace(/&raquo;/g, ">>")}</a>
                                        </li>
                                    );
                                })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CustomerList;
