import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteAgent, retriveAgents } from "../../services/AgentService";
import { AppContext } from "../../utils/AppProvider";

const Agent = () => {
    const [agents,setAgents] = useState([]);
    const [page, setPage] = useState(1);
    const [links, setLinks] = useState([]);
    const {admin_access} = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(()=>{
        if(admin_access != true)
            navigate('/');
        else
            getAgents();
    },[page])

    const getAgents = () =>{
        retriveAgents(page)
            .then((response) => {
                if(response?.data)
                    setAgents(response.data.data?.data);
                    setLinks(response.data.data?.links);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    const removeAgent = (id) => {
        deleteAgent(id)
            .then((response) => {
                let _agents = agents.filter((agent,index) => agent.id != id);
                setAgents(_agents);
                toast('Agent Deleted Successfully.',{type:'success'});
            })
            .catch((error) =>{
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Agents </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="d-inline-block">
                        <h6 className="m-0 font-weight-bold text-primary"> Listing </h6>
                        </div>
                        <div className="d-inline-block float-right">
                            <Link className="btn btn-success" to="/add-new-agent"> Add Agent </Link>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <thead>
                            <tr>
                                <th> Sr </th>
                                <th> Username </th>
                                <th> First Name </th>
                                <th> Last Name </th>
                                <th> Email </th>
                                <th> Phone </th>
                                <th> Status </th>
                                <th> Role </th>
                                <th> Register At </th>
                                <th> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                 agents.length > 0 ? 
                                    agents.map((agent,index) => {
                                        return (
                                            <tr key={index}>
                                                <td> { index + 1} </td>
                                                <td> { agent.username} </td>
                                                <td> { agent.firstname} </td>
                                                <td> { agent.lastname} </td>
                                                <td> { agent.email} </td>
                                                <td> { agent.mobile} </td>
                                                <td> { agent.status == 1 ? <span className="badge badge-success"> Active </span> : <span className="badge badge-danger"> Deactive</span>} </td>
                                                <td> { agent.role?.role_name} </td>
                                                <td> { agent.created_date} </td>
                                                <td> 
                                                    <Link className="btn btn-info" to={"/agents/view/" + agent.id}> View </Link> &nbsp; 
                                                    <Link className="btn btn-warning" to={"/agents/edit/" + agent.id}> Edit </Link> &nbsp; 
                                                    <button type="button" className="btn btn-danger" onClick={()=> removeAgent(agent.id)}> Delete </button> 
                                                </td>
                                            </tr>
                                        );
                                    }) 
                                : <tr>
                                    <td colSpan={10} className="empty-customers"> No Data Available. </td>
                                </tr> 
                            }
                            </tbody>
                       </table>

                       <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                            {
                                links.map((link,index) => {
                                    return (
                                        <li key={"a" +index} className={"page-item" + (link.active ? ' active' : '')}>
                                            <a className={"page-link" + (!link.url ? ' a-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); if(link.url) {setPage(link.url?.split('page=')[1])}}} tabIndex="-1">{link.label.replace(/&laquo;/g, "<<").replace(/&raquo;/g, ">>")}</a>
                                        </li>
                                    );
                                })
                                }
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default Agent;
