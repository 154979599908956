import reducer from "./reducer";
import { createContext, useReducer } from "react";

//Context and Provider
export const AppContext = createContext();
console.log("##", localStorage.getItem('admin_access'));
const Provider = ({ children }) => {
     
    const initialState = {
        search: '',
        agent: JSON.parse(localStorage.getItem('agent')),
        admin_access: localStorage.getItem('admin_access') === "true" || localStorage.getItem('admin_access') === true
    };
    
    const actions = {
        SET_SEARCH: "SET_SEARCH",
        SET_AGENT: "SET_AGENT",
        SET_ADMIN_ACCESS: "SET_ADMIN_ACCESS",
    };

  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    search: state.search,
    agent: state.agent,
    admin_access: state.admin_access,
    setSearch: (query) => {
      dispatch({ type: actions.SET_SEARCH, query });
    },
    setAgent: (payload) => {
      dispatch({ type: actions.SET_AGENT, payload });
    },
    setAdminAccess: (payload) => {
      dispatch({ type: actions.SET_ADMIN_ACCESS, payload });
    },
    
  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};


export default Provider;