import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const login = (data) => {
    return new Promise(function (resolve, reject) {
        axios.post('/agent/login', { ...data })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const register = (data) => {
    return new Promise(function (resolve, reject) {
        axios.post('/agent/register', { ...data })
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const logout = async() => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`,
        'Content-Type' : 'application/json',
        'Accept': 'application/json', 
    }

    return new Promise(function (resolve, reject) {
        axios.get('/agent/logout',{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}



const retriveStats = async() => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`,
    }

    return new Promise(function (resolve, reject) {
        axios.get('/agent/get-stats',{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


export { login, register, logout, retriveStats };