import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveOrderHistory, retrivePendingOrders } from "../../services/OrderService";

const History = () => {
    const [orders, setOrders] = useState([]);
    const [filters,setFilters] = useState({
        store:'',
        company:'',
        customer:'',
        time:''
    });
    const [page, setPage] = useState(1);
    const [links, setLinks] = useState([]);

    useEffect(() => {
        getOrders();
    }, [filters, page])

    const getOrders = () => {
        let filter = new URLSearchParams(filters).toString()
        retriveOrderHistory(filter,page )
            .then((response) => {
                if (response?.data)
                    setOrders(response.data.data?.data);
                    setLinks(response.data.data?.links);
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }

    const handleFilterChange = (e) =>{
        let {name, value} = e.target;
        setFilters({...filters,[name]:value});
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Order History</h1>
            <div className="card shadow mb-4">
                <div className="card-header py-3" style={{paddingBottom:'1px !important'}}>
                    <div className="d-inline-block">
                        <h6 className="m-0 font-weight-bold text-primary"> Filter </h6>
                    </div>
                    <div className="d-inline-block float-right">
                        {/* <div className="row">
                            <div className="col">
                                <label htmlFor="">Store</label>
                                <input type="text" className="form-control" />
                            </div>
                        </div> */}

                        <form className="form-inline" style={{paddingBottom: '1px !important'}}>
                            
                            <div className="input-group mb-2 mr-sm-2 col-lg col-sm-12">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Store</div>
                                </div>
                                <input type="text" name="store" value={filters.store} onChange={(e) => handleFilterChange(e)} className="form-control" id="inlineFormInputGroupUsername2" placeholder="Store" />
                            </div>
                            
                            <div className="input-group mb-2 mr-sm-2 col-lg col-sm-12" style={{width:'fit-content'}}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Company</div>
                                </div>
                                <input type="text" name="company" value={filters.company} onChange={(e) => handleFilterChange(e)} className="form-control" id="inlineFormInputGroupUsername2" placeholder="Company" />
                            </div>
                            
                            <div className="input-group mb-2 mr-sm-2 col-lg col-sm-12" style={{width:'fit-content'}}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Customer</div>
                                </div>
                                <input type="text" name="customer" value={filters.customer} onChange={(e) => handleFilterChange(e)} className="form-control" id="inlineFormInputGroupUsername2" placeholder="Customer" />
                            </div>
                            
                            <div className="input-group mb-2 mr-sm-2 col-lg col-sm-12" style={{width:'fit-content'}}>
                                <div className="input-group-prepend">
                                    <div className="input-group-text">Customer</div>
                                </div>
                                <select className="form-control" name="time" value={filters.time} onChange={(e) => handleFilterChange(e)} id="inlineFormInputGroupUsername2">
                                    <option value=''>Select Time</option>
                                    <option value='daily'> Daily </option>
                                    <option value='weekly'> Weekly </option>
                                    <option value='monthly'> Monthly </option>
                                </select>
                                {/* <input type="text" className="form-control" id="inlineFormInputGroupUsername2" placeholder="Customer" /> */}
                            </div>

                            

                            {/* <button type="button" className="btn btn-primary mb-2">Search</button> */}
                        </form>

                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table upload-image" >
                            <thead>
                                <tr>
                                    {/* <th>  </th> */}
                                    <th> Sr </th>
                                    <th> Order ID </th>
                                    <th> Customer ID </th>
                                    <th> Customer Name </th>
                                    <th> Email </th>
                                    <th> Phone </th>
                                    <th> Order Status </th>
                                    <th> Fulfillment Status </th>
                                    <th> Payment Type </th>
                                    <th> Total </th>
                                    <th> Order Date </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    orders.length > 0 ?
                                        orders.map((order, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td> {index + 1} </td>
                                                    <td> {order.id} </td>
                                                    <td> {order.customer_id} </td>
                                                    <td> {order.customer.name} </td>
                                                    <td> {order.email} </td>
                                                    <td> {order.billing_address?.phone} </td>
                                                    <td> {order.order_status == 'confirmed' ? <span className="badge badge-secondary">{order.order_status}</span> : order.order_status == 'approved' ? <span className="badge badge-success">{order.order_status}</span> : order.order_status == 'delivered' ? <span className="badge badge-primary">{order.order_status}</span> : <span className="badge badge-danger">{order.order_status}</span>} </td>
                                                    <td> {order.fulfillment_status == null ? <span className="badge badge-secondary"> Not Fulfilled </span> : order.fulfillment_status == 'partial' ? <span className="badge badge-warning">{order.fulfillment_status}</span> : <span className="badge badge-success">{order.fulfillment_status}</span>} </td>
                                                    <td> {order.payment_type} </td>
                                                    <td> ${order.total?.toFixed(2)} </td>
                                                    <td> {order.created_at} </td>
                                                </tr>
                                            );
                                        })
                                        : <tr>
                                            <td colSpan={10} className="empty-customers"> No Data Available. </td>
                                        </tr>
                                }
                            </tbody>
                        </table>

                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                            {
                                links.map((link,index) => {
                                    return (
                                        <li key={"a" +index} className={"page-item" + (link.active ? ' active' : '')}>
                                            <a className={"page-link" + (!link.url ? ' a-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); if(link.url) {setPage(link.url?.split('page=')[1])}}} tabIndex="-1">{link.label.replace(/&laquo;/g, "<<").replace(/&raquo;/g, ">>")}</a>
                                        </li>
                                    );
                                })
                                }
                            </ul>
                        </nav>

                    </div>
                </div>
            </div>
        </div>
    )
}


export default History;
