import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveProduct = async(page =1,perPage=10) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/items?page=${page}&perPage=${perPage}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const retriveItem = async(item_id) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/agent/items/${item_id}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const togglePromoteProduct = async(item_id, action) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/agent/items/toggle-promote?item_id=${item_id}&action=${action}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const setItemPurchaseLimit = async(item_id, limit) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.get(`/agent/item/set-purchase-limit?item_id=${item_id}&limit=${limit}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const saveItemImages = async(item_id,data) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        "Content-Type": "multipart/form-data",
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.post(`/agent/save-item-images/${item_id}`, data,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}
const removeItemImage = async(item_id,name) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        "Content-Type": "multipart/form-data",
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/delete-item-images?item_id=${item_id}&name=${name}`, {headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { retriveProduct, retriveItem,saveItemImages, togglePromoteProduct, setItemPurchaseLimit,removeItemImage };