import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveAllStates = async() => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get('/agent/states',{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const stateByIds = async(ids) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/states-by-ids?ids=${ids}`,{headers:headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}
const getPOSStates = async(id='') => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/posstates?id=${id}`,{headers:headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}
export { retriveAllStates, stateByIds,getPOSStates };