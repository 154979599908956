import { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { retriveProduct, setItemPurchaseLimit, togglePromoteProduct } from "../../services/ProductService";

const Products = () => {
    const [products,setProducts] = useState();
    const [flag,setFlag] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [itemId, setItemId] = useState('');
    const [itemIndex, setItemIndex] = useState('');
    const [purchaseLimit, setPurchaseLimit] = useState('');
    const [links, setLinks] = useState([]);

    useEffect(()=>{
        getProducts();
    },[flag, page, perPage]);

    const getProducts = () =>{
        retriveProduct(page, perPage).then((response)=>{
            setProducts(response.data.data?.data);
            setLinks(response.data.data?.links);
        }).catch((error) =>{
            console.log("Error:", error);
        })
    }

    const togglePromoteItem = (item_id, action) =>{
        togglePromoteProduct(item_id,action   ? 1 : 0 ).then((response)=>{
            setFlag(!flag);
            toast("Status Changed Successfully.",{type:'success'});
        }).catch((error) =>{
            if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
        })
    }

    const handleSetPurchaseLimit = () => {
        
        setItemPurchaseLimit(itemId,purchaseLimit).then((response)=>{
            products[itemIndex]['purchase_limit'] = purchaseLimit;
            setProducts([...products]);
            setItemId('');
            setItemIndex('');
            setPurchaseLimit('');
            toast("Purchase Limit set Successfully.",{type:'success'});
        }).catch((error) =>{
            if(error.response.status == 422)
                toast(error.response.data.message,{type: 'error'})
            else if(error.response.status == 500)
                toast(error.response.data.message,{type: 'error'})
            else    
                toast("Something went wrong", {type: 'error'});
        })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Products </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary d-inline-block"> Product Listing</h6>
                    <select name="perPage" id="perPage" className="form-control product-per-page" onChange={(e) => { setPage(1); setPerPage(e.target.value)}}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Item ID</th>
                                    <th>Title</th>
                                    <th>Category</th>
                                    <th>Brand</th>
                                    <th>Price</th>
                                    <th>Quantity</th>
                                    <th>Prchase Limit</th>
                                    <th>Promote</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {
                                    products?.map((product, index)=>{
                                        return (
                                            <tr key={index}>
                                                <td> <img src={process.env.REACT_APP_BASE_URL + '/storage/thumbnail/' + (product.item_image ? product.item_image : 'no_image_thumb.png')} /></td>
                                                <td> {product.id} </td>
                                                <td> {product.item_name} </td>
                                                <td> {product.category?.category_name} </td>
                                                <td> {product.brand?.brand_name} </td>
                                                <td> {product.price } </td>
                                                <td> {product.stock} </td>
                                                <td> 
                                                    <div className="row">
                                                        <div className="col">{product.purchase_limit} </div>  
                                                        <div className="col">
                                                            
                                                            <button className="btn btn-info btn-small" onClick={() => { setItemId(product.id); setItemIndex(index); setPurchaseLimit(product.purchase_limit)}}>Set</button> 
                                                        </div>
                                                    </div>
                                                </td>
                                                <td> 
                                                    <label className="switch"> 
                                                        <input type="checkbox" onChange={() => togglePromoteItem(product.id, !product.is_promoted)} checked={product.is_promoted} />
                                                        <span className="slider round"></span>
                                                    </label>    
                                                </td>
                                                <td> 
                                                    <Link to={"/products/" + product.id +"/upload-images"} className="btn btn-primary" ><i className="fas fa-fw fa-upload"></i> Upload Image</Link>
                                                    
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                            {
                                links.map((link,index) => {
                                    return (
                                        <li key={"a" +index} className={"page-item" + (link.active ? ' active' : '')}>
                                            <a className={"page-link" + (!link.url ? ' a-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); if(link.url) {setPage(link.url?.split('page=')[1])}}} tabIndex="-1">{link.label.replace(/&laquo;/g, "<<").replace(/&raquo;/g, ">>")}</a>
                                        </li>
                                    );
                                })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>


            {
                itemId ?
                <div className="modal fade show" style={{display:'block'}} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Item Purchase Limit</h5>
                      <button type="button" onClick={() => setItemId('')} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                      <div className="row align-items-baseline">
                          <label htmlFor="purchse_limit" className="font-weight-bold col-sm-3">Enter Limit:</label>
                          <input type="number" id="purchse_limit" min={1} max={100} value={purchaseLimit} onChange={(e) => setPurchaseLimit(e.target.value)} className="form-control col-sm-4" />
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button type="button" onClick={() => setItemId('')} className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                      <button type="button" onClick={() => handleSetPurchaseLimit()} className="btn btn-primary">Set</button>
                    </div>
                  </div>
                </div>
              </div>
                : null
            }
        </div>
    );
}


export default Products;