const actions = {
    SET_SEARCH: "SET_SEARCH",
    SET_AGENT: "SET_AGENT",
    SET_ADMIN_ACCESS: "SET_ADMIN_ACCESS",
   
};
//Reducer to Handle Actions
const reducer = (state, action) => {
    console.log('Reducer called');
    switch (action.type) {
      case actions.SET_SEARCH:
        return {
          ...state, search: action.query
        };
        
      case actions.SET_AGENT:
        return {
          ...state, agent: action.payload
        };
        
      case actions.SET_ADMIN_ACCESS:
        return {
          ...state, admin_access: action.payload
        };
      
      default:
        return state;
    }
  };

export default reducer;