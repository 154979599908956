import { useContext, useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createAgent, retriveAllRoles, retriveSingleAgent, updateAgent } from "../../services/AgentService";
import { AppContext } from "../../utils/AppProvider";

const ViewAgent = () => {
    const [roles, setRoles] = useState([]);
    const [agent, setAgent] = useState({
        username:'',
        firstname:'',
        lastname:'',
        email:'',
        gender:'',
        dob:'',
        mobile:'',
        address:'',
        city:'',
        state:'',
        country:'',
        postcode:'',
        role_id:'',
        status:'',
    });
    const navigate = useNavigate();
    const params = useParams();
    const {admin_access} = useContext(AppContext);

    useEffect(() => {
        if(admin_access != true)
            navigate('/');
        else{
            if(params.id)  getAgent();
        }
    }, [])

    
    
    const getAgent = () => {
        retriveSingleAgent(params.id)
            .then((response) => {
                if (response?.data){
                    delete response.data.data.password;
                    setAgent(response.data.data);
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }

    const handleInputChange = (e) => {
        let {name, value} = e.target
        setAgent({...agent,[name]:value});
    }

    const clearState = () => {
        setAgent({
            username:'',
            firstname:'',
            lastname:'',
            email:'',
            gender:'',
            dob:'',
            mobile:'',
            password:'',
            address:'',
            city:'',
            state:'',
            country:'',
            postcode:'',
            role_id:'',
            status:'',
        });
    }

    const handleSubmit = async() =>{
            await updateAgent(agent).then((response)=>{
                toast('Agent Updated Successfully',{type: 'success'})

                navigate('/agents');
            }).catch((error) =>{
                console.log("Error",error);
                if(error.response.status == 422)
                    toast(error.response.data.error,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
    }

    return (
        <div className="container-fluid mt-5">
            {/* <h1 className="h3 mb-2 text-gray-800">Add New Customer </h1> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 d-inline-block font-weight-bold text-primary"> Edit Agent </h6>
                    <div className="d-inline-block float-right">
                            <Link className="btn btn-primary" to="/agents"> Back </Link>
                    </div>
                </div>
                <div className="card-body">
                    <form id="agent-table" className="table-responsive">
                        <table className="table">
                            <tr>
                                <th> ID :</th>
                                <td> {agent.id}</td>
                            </tr>
                            <tr>
                                <th> Username : </th>
                                <td> {agent.username} </td>
                            </tr>
                            <tr>
                                <th> First Name : </th>
                                <td> {agent.firstname}</td>
                            </tr>
                            <tr>
                                <th> Last Name :</th>
                                <td> {agent.lastname}</td>
                            </tr>
                            <tr>
                                <th> Email :</th>
                                <td> {agent.email}</td>
                            </tr>
                            <tr>
                                <th> Phone :</th>
                                <td> {agent.mobile}</td>
                            </tr>
                            <tr>
                                <th> Gender :</th>
                                <td> {agent.gender}</td>
                            </tr>
                            <tr>
                                <th> DOB :</th>
                                <td> {agent.dob}</td>
                            </tr>
                            <tr>
                                <th> Address :</th>
                                <td> {agent.address}</td>
                            </tr>
                            <tr>
                                <th> City :</th>
                                <td> {agent.city}</td>
                            </tr>
                            <tr>
                                <th> State :</th>
                                <td> {agent.state}</td>
                            </tr>
                            <tr>
                                <th> Country :</th>
                                <td> {agent.country}</td>
                            </tr>
                            <tr>
                                <th> Role :</th>
                                <td> {agent.role?.role_name}</td>
                            </tr>
                            <tr>
                                <th> Status :</th>
                                <td> { agent.status == 1 ? <span className="badge badge-success"> Active </span> : <span className="badge badge-danger"> Deactive</span>} </td>
                            </tr>
                            <tr>
                                <th> Registered At :</th>
                                <td> {agent.created_date}</td>
                            </tr>
                        </table>
                        
                    </form>
                </div>
            </div>
        </div>
    )
}


export default ViewAgent;
