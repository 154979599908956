import { useEffect } from "react";
import { useState } from "react";
import { retriveApprovedCustomers, retriveRejectedCustomers } from "../../services/CustomerService";

const RejectedCustomer = () => {
    const [customers,setCustomers] = useState([]);

    useEffect(()=>{
        getRejectedcustomer();
    },[])

    const getRejectedcustomer = () =>{
        retriveRejectedCustomers()
            .then((response) => {
                if(response?.data)
                    setCustomers(response.data.data);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Rejected Customer </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Listing </h6>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <thead>
                            <tr>
                                <th> Sr </th>
                                <th> Name </th>
                                <th> Email </th>
                                <th> Company </th>
                                <th> Reason </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                customers.length > 0 ? 
                                    customers.map((customer,index) => {
                                        return (
                                            <tr key={index}>
                                                <td> { index + 1} </td>
                                                {/* <td> { customer.pos_customer_id} </td> */}
                                                <td> { customer.name} </td>
                                                <td> { customer.email} </td>
                                                <td> { customer.company_name} </td>
                                                <td> { customer?.rejection_message.message} </td>
                                            </tr>
                                        );
                                    }) 
                                : <tr>
                                    <td colSpan={6} className="empty-customers"> No Data Available. </td>
                                </tr>
                            }
                            
                          
                            </tbody>
                       </table>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RejectedCustomer;
