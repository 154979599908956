import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveOrders = async(page =1, per_page = 10) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/orders?page=${page}&per_page=${per_page}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const retrivePendingOrders = async(page =1, per_page = 10) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/pending-orders?page=${page}&per_page=${per_page}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveOrderHistory = async(filter, page =1, per_page = 10) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/order-history?${filter}&page=${page}&per_page=${per_page}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const retriveOrderById = async(id) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/order/${id}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const approveOrderItems = async(order_id,item_ids) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/approve-order-items?order_id=${order_id}&item_ids=${item_ids}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const rejectOrderItems = async(order_id,item_ids) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/reject-order-items?order_id=${order_id}&item_ids=${item_ids}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const changeOrderStatus = async(order_id,status) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/change-order-status?order_ids=${order_id}&status=${status}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const pushOrderToPOS = async(order_id) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }

    return new Promise(function (resolve, reject) {
        axios.post(`/agent/push-order-to-pos`,{order_id: order_id},{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


export { retriveOrders, retriveOrderById, approveOrderItems, rejectOrderItems, changeOrderStatus, retrivePendingOrders, retriveOrderHistory, pushOrderToPOS };