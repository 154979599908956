import Home from "../components/Home/Home"
import Login from "../components/Auth/Login"
import Register from "../components/Auth/Register"
import Products from "../components/Product/Products";
import ProductEdit from "../components/Product/UploadImage";
import ApprovedCustomer from "../components/Customer/ApprovedCustomer";
import PendingAplications from "../components/Customer/PendingAplications";
import AddCustomer from "../components/Customer/AddCustomer";
import RejectedCustomer from "../components/Customer/RejectedCustomer";
import EditCustomer from "../components/Customer/EditCustomer";
import Orders from "../components/Orders/Orders";
import ManageOrder from "../components/Orders/ManageOrder";
import PendingOrders from "../components/Orders/PendingOrders";
import History from "../components/Orders/History";
import Agent from "../components/Agent/Agent";
import AddAgent from "../components/Agent/AddAgent";
import EditAgent from "../components/Agent/EditAgent";
import ViewAgent from "../components/Agent/ViewAgent";
import CustomerList from "../components/Customer/CustomerList";

const INDEX = '/';
const LOGIN = '/login';
const REGISTER = '/register';
const PRODUCTS = '/products';
const PRODUCT_EDIT = '/products/:id/upload-images';
const CUSTOMER = '/customer';
const APPROVED_CUSTOMER= "/approved-customers";
const REJECTED_CUSTOMER= "/rejected-customers";
const EDIT_CUSTOMER= "/customer/edit/:id";
const PENDING_APPLCATIONS= "/pending-application";
const ADD_CUSTOMER= "/add-customer";
const ORDERS= "/orders";
const MANAGE_ORDER= "/orders/manage/:id";
const PENDING_ORDER= "/pending-orders";
const ORDER_HISTORY= "/order-history";
const AGENTS= "/agents";
const ADD_NEW_AGENT= "/add-new-agent";
const EDIT_AGENT= "/agents/edit/:id";
const VIEW_AGENT= "/agents/view/:id";

const routes = [
    {
        path: INDEX,
        exact: true,
        private: true,
        page: {
            component: Home,
            title: 'Home'
        }
    },
    {
        path: LOGIN,
        exact: true,
        private: false,
        page: {
            component: Login,
            title: 'Login'
        }
    },
    {
        path: REGISTER,
        private: false,
        exact: true,
        page: {
            component: Register,
            title: 'Register'
        }
    },
    {
        path: PRODUCTS,
        private: true,
        exact: true,
        page: {
            component: Products,
            title: 'Product'
        }
    },
    {
        path: PRODUCT_EDIT,
        private: true,
        exact: true,
        page: {
            component: ProductEdit,
            title: 'Product'
        }
    },
    {
        path: CUSTOMER,
        private: true,
        exact: true,
        page: {
            component: CustomerList,
            title: 'Customer List'
        }
    },
    {
        path: APPROVED_CUSTOMER,
        private: true,
        exact: true,
        page: {
            component: ApprovedCustomer,
            title: 'Approved Customer'
        }
    },
    {
        path: REJECTED_CUSTOMER,
        private: true,
        exact: true,
        page: {
            component: RejectedCustomer,
            title: 'Rejected Customer'
        }
    },
    {
        path: EDIT_CUSTOMER,
        private: true,
        exact: true,
        page: {
            component: EditCustomer,
            title: 'Edit Customer'
        }
    },
    {
        path: PENDING_APPLCATIONS,
        private: true,
        exact: true,
        page: {
            component: PendingAplications,
            title: 'Approved Customer'
        }
    },
    {
        path: ADD_CUSTOMER,
        private: true,
        exact: true,
        page: {
            component: AddCustomer,
            title: 'Add Customer'
        }
    },
    {
        path: ORDERS,
        private: true,
        exact: true,
        page: {
            component: Orders,
            title: 'Orders'
        }
    },
    {
        path: MANAGE_ORDER,
        private: true,
        exact: true,
        page: {
            component: ManageOrder,
            title: 'Manage Order'
        }
    },
    {
        path: PENDING_ORDER,
        private: true,
        exact: true,
        page: {
            component: PendingOrders,
            title: 'Pending Orders'
        }
    },
    {
        path: ORDER_HISTORY,
        private: true,
        exact: true,
        page: {
            component: History,
            title: 'Pending Orders'
        }
    },
    {
        path: AGENTS,
        private: true,
        exact: true,
        page: {
            component: Agent,
            title: 'Agents'
        }
    },
    {
        path: ADD_NEW_AGENT,
        private: true,
        exact: true,
        page: {
            component: AddAgent,
            title: 'Add Agent'
        }
    },
    {
        path: EDIT_AGENT,
        private: true,
        exact: true,
        page: {
            component: EditAgent,
            title: 'Edit Agent'
        }
    },
    {
        path: VIEW_AGENT,
        private: true,
        exact: true,
        page: {
            component: ViewAgent,
            title: 'View Agent'
        }
    },
];

export default routes;