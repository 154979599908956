import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { changeOrderStatus, retriveOrders } from "../../services/OrderService";

const Orders = () => {
    const [orders,setOrders] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [page, setPage] = useState(1);
    const [links, setLinks] = useState([]);

    useEffect(()=>{
        getOrders();
    },[ page])

    const getOrders = () =>{
        retriveOrders(page)
            .then((response) => {
                if(response?.data)
                    setOrders(response.data.data?.data);
                    setLinks(response.data.data?.links);
            })
            .catch((error) =>{
                console.log("Error:", error);
            })
    }

    const handleSelectItem = (id, value) => {
        if(value === true){
            setSelectedOrders([...selectedOrders, id]);
        }else{
            setSelectedOrders(selectedOrders.filter((el) => el != id));
        }
    }

    const handleOrderStatusChange = (status) => {
        if(selectedOrders.length <= 0){
            toast('Please Select Order',{type:'error'});
            return true;
        }
        let order_ids = selectedOrders.join(",");
        changeOrderStatus(order_ids, status)
            .then((response) => {
                if(response?.data){
                    let _orders = orders.map((order,index) => {
                        if(selectedOrders.includes(order.id)){
                            order['order_status'] = status;
                        }
                        return order;
                    });
                    setOrders(_orders);
                    setSelectedOrders([]);
                    toast("Order Status Successfully.",{type:'success'});
                }
            })
            .catch((error) =>{
                console.log("Error:", error);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            })
    }

    return (
        <div className="container-fluid mt-5">
            <h1 className="h3 mb-2 text-gray-800">Orders </h1>

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <div className="d-inline-block">
                        <h6 className="m-0 font-weight-bold text-primary"> Listing </h6>
                        </div>

                    <div className="d-inline-block float-right">
                        
                        <button disabled={orders.length == 0} className="btn btn-success mb-2" onClick={() => handleOrderStatusChange('approved')}>Approve</button>
                        <button disabled={orders.length == 0} className="btn btn-danger mr-1 ml-1 mb-2" onClick={() => handleOrderStatusChange('rejected')}>Reject</button>
                        <button disabled={orders.length == 0} className="btn btn-primary mr-1 ml-1 mb-2" onClick={() => handleOrderStatusChange('delivered')}>Mark Delivered</button>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                       <table className="table upload-image" >
                        <thead>
                            <tr>
                                <th>  </th>
                                <th> Sr </th>
                                <th> Customer ID </th>
                                <th> Email </th>
                                <th> Order Status </th>
                                <th> Fulfillment Status </th>
                                <th> Payment Type </th>
                                <th> Total </th>
                                <th> Date </th>
                                <th> Action </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                orders.length > 0 ? 
                                    orders.map((order,index) => {
                                        return (
                                            <tr key={index}>
                                                <td> <input type="checkbox" name="" checked={selectedOrders.includes(order.id)} onChange={(e) => handleSelectItem(order.id ,e.target.checked)} id="" /> </td>
                                                <td> { index + 1} </td>
                                                <td> { order.customer_id} </td>
                                                <td> { order.email} </td>
                                                <td> { order.order_status == 'confirmed' ? <span className="badge badge-secondary">{order.order_status}</span> : order.order_status == 'approved' ? <span className="badge badge-success">{order.order_status}</span> : order.order_status == 'delivered' ? <span className="badge badge-primary">{order.order_status}</span> : <span className="badge badge-danger">{order.order_status}</span>} </td>
                                                <td> { order.fulfillment_status == null ? <span className="badge badge-secondary"> Not Fulfilled </span> : order.fulfillment_status == 'partial' ? <span className="badge badge-warning">{order.fulfillment_status}</span> : <span className="badge badge-success">{order.fulfillment_status}</span>} </td>
                                                <td> { order.payment_type} </td>
                                                <td> ${ order.total?.toFixed(2)} </td>
                                                <td> { order.created_at} </td>
                                                <td> 
                                                    <Link className="btn btn-info" to={"/orders/manage/" + order.id}> Manage </Link> &nbsp; 
                                                </td>
                                            </tr>
                                        );
                                    }) 
                                : <tr>
                                    <td colSpan={10} className="empty-customers"> No Data Available. </td>
                                </tr>
                            }
                            </tbody>
                       </table>

                       <nav aria-label="Page navigation example">
                            <ul className="pagination justify-content-center">
                            {
                                links.map((link,index) => {
                                    return (
                                        <li key={"a" +index} className={"page-item" + (link.active ? ' active' : '')}>
                                            <a className={"page-link" + (!link.url ? ' a-disabled' : '')} href="" onClick={(e) => { e.preventDefault(); if(link.url) {setPage(link.url?.split('page=')[1])}}} tabIndex="-1">{link.label.replace(/&laquo;/g, "<<").replace(/&raquo;/g, ">>")}</a>
                                        </li>
                                    );
                                })
                                }
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Orders;
