import { useEffect } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCustomerCategories, retriveSingleCustomer, updateCustomer,deleteCertificate} from "../../services/CustomerService";
import { getPOSStates } from "../../services/StateService";
import { getAllCountry } from "../../services/CountryService";

const EditCustomer = () => {
    const [country, setCountry] = useState([]);
    const [states, setStates] = useState([]);
    const [customerCategory, setCustomerCategory] = useState([]);
    const [customer, setCustomer] = useState({});
    const navigate = useNavigate();
    let date = new Date();
    date = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, "0") + '-' + date.getDate();
    const params = useParams();

    useEffect(() => {
        if(params.id){
            getCustomer();
        }
        getCountry();
        getCustomerCategory();
    }, [])

    const getCountry = async () => {
        await getAllCountry().then((response) => {
            setCountry(response.data);
            var country_id = document.getElementById('country_id').value;
            getPOSState(country_id);
        }).catch((error) => {
            console.log("Error:", error);
        });
    }
    const getCustomerCategory = async () => {
        await getCustomerCategories().then((response) => {
            setCustomerCategory(response.data.data);
        }).catch((error) => {
            console.log("Error:", error);
        });
    }
    const getPOSState = async (id) => {
        await getPOSStates(id).then((response) => {
            setStates(response.data.data);
        }).catch((error) => {
            console.log("Error:", error);
        });
    }
    
    
    const getCustomer = () => {
        retriveSingleCustomer(params.id)
            .then((response) => {
                if (response.data?.data){
                    let data = response.data.data;
                    data.mobile = formattedNumber(data.mobile);
                    data.phone = formattedNumber(data.phone);
                    data.manager_number = formattedNumber(data.manager_number);
                    setCustomer(data);
                }
            })
            .catch((error) => {
                console.log("Error:", error);
            })
    }
    const formattedNumber = (value) => {
        if(value != null){
            var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
            return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
        }
    }

    const handleCertificateDelete = async(e, type) =>{
        e.preventDefault();
        let data = [];
        data.id = customer.pos_customer_id;
        type == 'tax' ? data['tax'] = customer.tax_certificate : data['tob'] = customer.tob_certificate;

        await deleteCertificate(data).then((response) => {
            toast('Certificate Removed Successfully.',{type:'success'});

            setCustomer({...customer,[(type == 'tax' ? 'tax_certificate' : 'tob_certificate')]: ''});
        }).catch((error) => {
            console.log("Error:", error);
        });
    }

    const handleInputChange = (e) => {
        let {name, value} = e.target
        
        if(name == 'country_id' && customer.state_id != value){
            getPOSState(value);
        }

        if(name == 'phone' || name == 'mobile' || name == 'manager_number'){
            if(value != null){
                var x = value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                value = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
            }
        }
        setCustomer({...customer,[name]:value});

        if(name == 'tax_certificate' || name == 'tob_certificate'){
            setCustomer({ ...customer, [name]: e.target.files[0]});
            return;
        }

    }

    const clearState = () => {
        setCustomer({
            name_prefix:'Mr.',
            name: '',
            email: '',
            company_name: '',
            address: '',
            pincode:'',
            city: '',
            country_id:'',
            state_id: '',
            tax_id: '',
            tax_expiry_date: '',
            tax_certificate: '',
            phone:'',
            cc_tax_id:'',
            dba:'',
            mobile:'',
            tob_license_no:'',
            tob_expiry_date:'',
            tob_certificate:'',
            customer_commission:'',
            manager_number:'',
        });
    }

    const handleSubmit = async(shouldReset = false) =>{
        if(validateInput()){
            var phonenumber = customer?.phone?.replace(/\D/g, '');
            var mobile = customer?.mobile?.replace(/\D/g, '');
            var manager_number = customer?.manager_number?.replace(/\D/g, '');
            let formdata = new FormData();
            formdata.append('id',customer.id);
            formdata.append('pos_customer_id',customer.pos_customer_id);
            formdata.append('name_prefix',customer.name_prefix ?? '');
            formdata.append('name',customer.name);
            formdata.append('email',customer.email);
            formdata.append('company_name',customer.company_name);
            formdata.append('address',customer.address);
            formdata.append('pincode',customer.pincode);
            formdata.append('city',customer.city);
            formdata.append('country_id',customer.country_id);
            formdata.append('state_id',customer.state_id);
            formdata.append('tax_id',customer.tax_id ?? '');
            formdata.append('tax_expiry_date',customer.tax_expiry_date ?? '');
            formdata.append('tax_certificate',customer.tax_certificate);
            formdata.append('password',customer.password);
            formdata.append('phone',phonenumber ?? '');
            formdata.append('manager_number',manager_number ?? '');
            formdata.append('cc_tax_id',customer.cc_tax_id);
            formdata.append('dba',customer.dba ?? '');
            formdata.append('mobile',mobile);
            formdata.append('tob_license_no',customer.tob_license_no);
            formdata.append('tob_expiry_date',customer.tob_expiry_date ?? '');
            formdata.append('tob_certificate',customer.tob_certificate ?? '');
            formdata.append('customer_commission',customer.customer_commission ?? '');
            formdata.append('should_reset', shouldReset ? 1 : 0);
            formdata.append('status', customer.status);
            
            await updateCustomer(formdata).then((response)=>{
                
                toast('Customer Updated Successfully',{type: 'success'})

                clearState();
                navigate('/approved-customers');
            }).catch((error) =>{
                console.log("e",error.response);
                if(error.response.status == 422)
                    toast(error.response.data.message,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
        }
    }

    const validateInput = () => {
        
        console.log('tob_license_no',customer.tob_license_no);
        if (customer.name == '') {
            toast("Please enter name.", {
                type: 'error'
            });
            return false
        }
        if (customer.company_name == '') {
            toast("Please enter company name.", {
                type: 'error'
            });
            return false
        }
        if (customer.email == '') {
            toast("Please enter email.", {
                type: 'error'
            });
            return false
        }
        if(customer.address == ''){
            toast("Please enter addess.",{
                type:'error'
            });
            return false
        }
        if(customer.country_id == ''){
            toast("Please select country.",{
                type:'error'
            });
            return false
        }
        if(customer.state_id == ''){
            toast("Please select states.",{
                type:'error'
            });
            return false
        }

        if(customer.city == ''){
            toast("Please enter city.",{
                type:'error'
            });
            return false
        }
        if(customer.pincode == '' || customer.pincode == null){
            toast("Please enter postcode.",{
                type:'error'
            });
            return false
        }

        if(customer.mobile == '' || customer.mobile == null){
            toast("Please enter Primary Contact.",{
                type:'error'
            });
            return false
        }
        if(customer.tob_license_no == '' || customer.tob_license_no == null){
            toast("Please Enter Tobacco ID.",{
                type:'error'
            });
            return false
        }
        if(customer.cc_tax_id == '' || customer.cc_tax_id == null){
            toast("Please select customer category.",{
                type:'error'
            });
            return false
        }
        return true;
    }

    const handleSubmitAndReset = () => {
        handleSubmit(true);
    }

    return (
        <div className="container-fluid mt-5">
            {/* <h1 className="h3 mb-2 text-gray-800">Add New Customer </h1> */}

            <div className="card shadow mb-4">
                <div className="card-header py-3">
                    <h6 className="m-0 font-weight-bold text-primary"> Edit Customer </h6>
                </div>
                <div className="card-body">
                    <form id="customer-form" className="">
                        <div className="row">
                          <div className="col-lg col-sm-12 row">
                                <div className="col-sm-3">
                                <label htmlFor="Prefix">Prefix:<span className="required_red">*</span></label>
                                <select className="form-control" name="name_prefix" value={customer.name_prefix} onChange={(e)=> handleInputChange(e)} id="Prefix" >
                                    <option value="Mr." selected>Mr.</option>
                                    <option value="Ms.">Ms.</option>
                                    <option value="Mrs">Mrs.</option>
                                    <option value="Miss.">Miss.</option>
                                </select>
                                </div>
                                <div className="col-sm-9">
                                    <label htmlFor="name" className="ml-3">Name:<span className="required_red">*</span></label>
                                    <input type="text" name="name" value={customer.name} onChange={(e) => handleInputChange(e)} id="name" className="form-control ml-3" placeholder="Enter Full Name" />
                                </div>
                            </div>
                            <div className="col-lg col-sm-12 ml-3">
                            <label htmlFor="cmp_name">Company Name:<span className="required_red">*</span></label>
                            <input type="text" id="cmp_name" name="company_name" value={customer.company_name} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Company Name" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                            <label htmlFor="email">Email:<span className="required_red">*</span></label>
                            <input type="text" id="email" name="email" value={customer.email} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Email Address" />
                            </div>
                            <div className="col-lg col-sm-12">
                            <label htmlFor="address">Address:<span className="required_red">*</span></label>
                                <input type="text" id="address" name="address" value={customer.address} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Address" />
                            </div>
                        </div>
                        
                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                            <label htmlFor="country_id">Country:<span className="required_red">*</span></label>
                                <select name="country_id" id="country_id" value={customer.country_id} onChange={(e) => handleInputChange(e)} className="form-control">
                                    <option value="">Select State</option>
                                        {
                                            country.map((country,index) =>{
                                                return (
                                                    <option key={index} value={country.id}> {country.country}</option>
                                                );
                                            })
                                        }
                                </select>
                            </div>
                            <div className="col-lg col-sm-12">
                            <label htmlFor="state_id">State:<span className="required_red">*</span></label>
                            <select name="state_id" id="state_id" value={customer?.state_id} onChange={(e) => handleInputChange(e)} className="form-control">
                                    <option value="">Select State</option>
                                    {
                                        states.map((state,index) =>{
                                            return (
                                                <option key={index} value={state.id}> {state.state}</option>
                                            );
                                        })
                                    }
                                </select>    
                                
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                                <label htmlFor="city"> City:<span className="required_red">*</span></label>
                                <input type="text" id="city" name="city" value={customer.city} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter City" />
                            </div>
                            <div className="col-lg col-sm-12">
                                <label htmlFor="pincode">Postcode:<span className="required_red">*</span></label>
                                <input type="number" id="pincode" name="pincode" value={customer.pincode} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Postcode" />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                            <label htmlFor="mobile">Primary Contact:<span className="required_red">*</span></label>
                                <input type="text" name="mobile" id="mobile" value={customer.mobile} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Mobile Number" />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                            <label htmlFor="phone">Secondary Contact:</label>
                                <input type="text" id="phone" name="phone" value={customer.phone} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Phone" />
                            </div>
                        </div>    
                        <div className="row mt-2">
                            <div className="col-lg-6 col-sm-12">
                                <label htmlFor="manager_number">Manager Number:</label>
                                <input type="text" name="manager_number" id="manager_number" value={customer.manager_number} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Manager Number" />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <label htmlFor="customer_commission">Commision:</label>
                                <input type="number" name="customer_commission" id="customer_commission" value={customer.customer_commission} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Commision" />
                            </div>
                        </div>      
                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                                <label htmlFor="tax_id">Tax ID:</label>
                                <input type="text" name="tax_id" id="tax_id" value={customer?.tax_id} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Tax ID" />
                            </div>
                            <div className="col-lg col-sm-12">
                                <label htmlFor="tax_expiry_date">Tax Valid Till:</label>
                                <input type="date" name="tax_expiry_date" id="tax_expiry_date" min={date} value={customer.tax_expiry_date} onChange={(e) => handleInputChange(e)} className="form-control" />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                                <label htmlFor="tax_certificate">Tax Certificate:</label>
                                <input type="file" name="tax_certificate" id="tax_certificate" value={()=>{}} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Select Tax Certificte" />
                                {
                                    (customer.tax_certificate != null &&  customer.tax_certificate != '')
                                        ?
                                        <>
                                        <a href={process.env.REACT_APP_BASE_URL+'/storage/tax_certificates/'+ customer.tax_certificate} target="_blank"><i className="fa fa-eye" aria-hidden="true"> View Certificate</i></a>
                                        <a className="text-danger ml-2" href="" onClick={(e) => handleCertificateDelete(e, 'tax')}  target="_blank"><i className="fa fa-trash" aria-hidden="true"> Delete </i></a>
                                        </>
                                    :
                                        null
                                }
                            </div>
                            <div className="col-lg col-sm-12">
                                <label htmlFor="dba">Doing Business As:</label>
                                <input type="text" name="dba" id="dba" value={customer.dba} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Doing Business As" />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg col-sm-12">
                            <label htmlFor="tob_license_no">Tobacco ID:<span className="required_red">*</span></label>
                                <input type="text" name="tob_license_no" id="tob_license_no" value={customer.tob_license_no} onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Enter Tobacco ID" />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <label htmlFor="tob_expiry_date">Tobacco Valid Till:</label>
                                <input type="date" name="tob_expiry_date" id="tob_expiry_date" min={date} value={customer.tob_expiry_date} onChange={(e) => handleInputChange(e)} className="form-control" />
                            </div>
                        </div>  
                        <div className="row mt-2">
                            <div className="col-lg-6 col-sm-12">
                                <label htmlFor="tob_certificate">Tobacco Certificate:</label>
                                <input type="file" name="tob_certificate" id="tob_certificate" onChange={(e) => handleInputChange(e)} className="form-control" placeholder="Select Tob Certificte" />
                                {
                                    (customer.tob_certificate != null && customer.tob_certificate != '') ?
                                        <>
                                            <a href={process.env.REACT_APP_BASE_URL+'/storage/tobacco_certificates/'+ customer.tob_certificate} target="_blank"><i className="fa fa-eye" aria-hidden="true"> View Certificate</i></a>
                                            <a className="text-danger ml-2" href="" onClick={(e) => handleCertificateDelete(e, 'tob')}  target="_blank"><i className="fa fa-trash" aria-hidden="true"> Delete </i></a>                                        
                                        </>
                                        :null
                                }
                            </div>
                            <div className="col-lg col-sm-12">
                            <label htmlFor="cc_tax_id">Customer Membership:<span className="required_red">*</span></label>
                            <select name="cc_tax_id" id="cc_tax_id" value={customer.cc_tax_id} onChange={(e) => handleInputChange(e)} className="form-control">
                                <option value="">Select Membership Type</option>
                                    {
                                        customerCategory.map((cc,index) =>{
                                            return (
                                                <option key={index} value={cc.cc_id}> {cc.cc_name}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>     

                        <div className="row mt-2">
                            <div className="col text-center">
                                <input type="button" className="btn btn-danger" onClick={() => clearState()} value="Clear" />
                                <input type="button" className="btn btn-success ml-2" value="Update" onClick={() => handleSubmit()} />
                                {
                                    customer.status == 'rejected' ?
                                        <input title="Remove reject status" type="button" className="btn btn-info ml-2" value="Update & Reset" onClick={() => handleSubmitAndReset()} />
                                    :null
                                }
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}


export default EditCustomer;
