import { useEffect } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify";
import { login } from "../../services/AuthService";
import axios from "axios";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const Login = () => {
    const [state, setState] = useState({
        email:'',
        password:''
    });
    const { setAdminAccess, setAgent } = useContext(AppContext);

    const navigate = useNavigate();
    const [passwordType,setpasswordType] = useState(false);

    const handleInputChange =(e)=>{
        let {name, value} = e.target;
        setState({...state, [name]:value});
    }   

    useEffect(()=>{
        let el = document.getElementsByTagName('body')[0];
        el.classList.toggle('bg-gradient-primary');

        return () => {
            el.classList.toggle('bg-gradient-primary');
        };
    },[]);

    const handleSubmit = async () =>{
        if(validateInput()){
            await login(state).then((response)=>{
                let data = response.data.data
                localStorage.setItem('agent_authenticated', 1);
                localStorage.setItem('agent-token', data.token);
                localStorage.setItem('agent', JSON.stringify(data.agent));
                localStorage.setItem('admin_access', data.agent.admin_access);
                axios.defaults.headers.common['Authorization'] = data.token;
                
                delete data.agent.password;
                setAdminAccess(data.agent?.admin_access);
                setAgent(data.agent);
                
                toast('Agent Login Successfully',{type: 'success'})

                navigate("/");
            }).catch((error) =>{
                if(error.response.status == 422)
                    toast(error.response.data.error,{type: 'error'})
                else if(error.response.status == 500)
                    toast(error.response.data.message,{type: 'error'})
                else    
                    toast("Something went wrong", {type: 'error'});
            });
        }
    }

    const validateInput = () => {
        if(state.email == ''){
            toast("Please enter email.",{
                type:'error'
            });
            return false
        }
        if(state.password == ''){
            toast("Please enter password.",{
                type:'error'
            });
            return false
        }
        
        return true;
    }
    const _handleKeyDown = (e) =>{
        if (e.key === 'Enter') {
            handleSubmit();
          }
    }
    return (
        <div className="container">

        <div className="row justify-content-center">

            <div className="col-xl-10 col-lg-12 col-md-9">

                <div className="card o-hidden border-0 shadow-lg my-5">
                    <div className="card-body p-0">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                            <div className="col-lg-6">
                                <div className="p-5">
                                    <div className="text-center">
                                        <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                                    </div>
                                    <form className="user">
                                        <div className="form-group">
                                            <input onKeyDown={(e)=> _handleKeyDown(e)} type="email" className="form-control form-control-user"
                                                id="exampleInputEmail" name="email" value={state.email} onChange={(e) => handleInputChange(e)} aria-describedby="emailHelp"
                                                placeholder="Enter Email Address..." />
                                        </div>
                                        <div className="form-group">
                                            <input onKeyDown={(e)=> _handleKeyDown(e)} type={passwordType?'text':'password'} name="password" value={state.password} onChange={(e) => handleInputChange(e)} className="form-control form-control-user"
                                                id="exampleInputPassword" placeholder="Password" />
                                                <i className={"show-password " + (passwordType?"fas fa-eye":"fas fa-eye-slash")}  title="Show Password" onClick={()=> setpasswordType(!passwordType)}></i>
                                        </div>
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox small">
                                                <input type="checkbox" className="custom-control-input" id="customCheck" />
                                                <label className="custom-control-label" htmlFor="customCheck">Remember
                                                    Me</label>
                                            </div>
                                        </div>
                                        <button type="button" onClick={() => handleSubmit()} className="btn btn-primary btn-user btn-block">
                                            Login
                                        </button>
                                        
                                    </form>
                                    <hr />
                                    <div className="text-center">
                                        <a className="small" href="#forgot-password.html">Forgot Password?</a>
                                    </div>
                                    <div className="text-center">
                                        <Link className="small" to="/register">Create an Account!</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>
    );
}

export default Login;