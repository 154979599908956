import { useState } from "react";
import { Link, useNavigate,useLocation } from "react-router-dom";
import { logout } from "../../services/AuthService";
import { toast } from "react-toastify";
import { AppContext } from "../../utils/AppProvider";
import { useContext } from "react";

const Navbar = () => {
    const [show,setShow] = useState(false);
    const navigate = useNavigate();
    const { setAdminAccess, setAgent, agent} = useContext(AppContext);

    const location = useLocation();
    const [customerMenu,setCustomerMenu] = useState(false);
    const [orderMenu,setOrderMenu] = useState(false);
    const [agentMenu,setAgentMenu] = useState(false);
    const { admin_access } = useContext(AppContext);
    const customer_paths =['/add-customer','/pending-application','/approved-customers','/rejected-customers'];
    const order_paths =['/orders','/pending-orders','/order-history'];
    const agent_paths =['/agents'];

    const [mobileMenu,setMobileMenu] =useState(false);

    const logoutAgent = () =>{
        logout().then((response)=>{
            localStorage.removeItem('agent_authenticated');
            localStorage.removeItem('agent-token');
            localStorage.removeItem('agent');
            localStorage.removeItem('admin_access');
            
            setAdminAccess(null);
            setAgent(null);

            navigate('/login');
        }).catch((error) =>{
            if (error.response.status == 422)
                toast(error.response.data.message, { type: 'error' })
            else if (error.response.status == 500)
                toast(error.response.data.message, { type: 'error' })
            else
                toast("Something went wrong", { type: 'error' });
        })
    }
    return (
        <>
        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
            <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3 sidebar-toggle js-sidebar-toggle" onClick={()=>setMobileMenu(!mobileMenu)}>
                <i className="fa fa-bars"></i>
            </button>
            <Link className="sidebar-brand d-flex align-items-center justify-content-center mobile-logo d-lg-none" to="/">
              <div className="sidebar-brand-icon rotate-n-15">
                  <i className="fas fa-laugh-wink"></i>
              </div>
              <div className="sidebar-brand-text mx-3">B2B Agent </div>
            </Link>
            <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
                <div className="input-group">
                    <input type="text" className="form-control bg-light border-0 small" placeholder="Search for..."
                        aria-label="Search" aria-describedby="basic-addon2" />
                    <div className="input-group-append">
                        <button className="btn btn-primary" type="button">
                            <i className="fas fa-search fa-sm"></i>
                        </button>
                    </div>
                </div>
            </form>

            <ul className="navbar-nav ml-auto">
                <li className="nav-item dropdown no-arrow d-sm-none">
                    <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-search fa-fw"></i>
                    </a>

                    <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                        aria-labelledby="searchDropdown">
                        <form className="form-inline mr-auto w-100 navbar-search">
                            <div className="input-group">
                                <input type="text" className="form-control bg-light border-0 small"
                                    placeholder="Search for..." aria-label="Search"
                                    aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-primary" type="button">
                                        <i className="fas fa-search fa-sm"></i>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </li>

                {/* <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-bell fa-fw"></i>

                        <span className="badge badge-danger badge-counter">3+</span>
                    </a>

                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="alertsDropdown">
                        <h6 className="dropdown-header">
                            Alerts Center
                        </h6>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="mr-3">
                                <div className="icon-circle bg-primary">
                                    <i className="fas fa-file-alt text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 12, 2019</div>
                                <span className="font-weight-bold">A new monthly report is ready to download!</span>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="mr-3">
                                <div className="icon-circle bg-success">
                                    <i className="fas fa-donate text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 7, 2019</div>
                                $290.29 has been deposited into your account!
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="mr-3">
                                <div className="icon-circle bg-warning">
                                    <i className="fas fa-exclamation-triangle text-white"></i>
                                </div>
                            </div>
                            <div>
                                <div className="small text-gray-500">December 2, 2019</div>
                                Spending Alert: We've noticed unusually high spending for your account.
                            </div>
                        </a>
                        <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                    </div>
                </li> */}


                {/* <li className="nav-item dropdown no-arrow mx-1">
                    <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="fas fa-envelope fa-fw"></i>

                        <span className="badge badge-danger badge-counter">7</span>
                    </a>

                    <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                        aria-labelledby="messagesDropdown">
                        <h6 className="dropdown-header">
                            Message Center
                        </h6>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="images/undraw_profile_1.svg"
                                    alt="..." />
                                <div className="status-indicator bg-success"></div>
                            </div>
                            <div className="font-weight-bold">
                                <div className="text-truncate">Hi there! I am wondering if you can help me with a
                                    problem I've been having.</div>
                                <div className="small text-gray-500">Emily Fowler · 58m</div>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="images/undraw_profile_2.svg"
                                    alt="..." />
                                <div className="status-indicator"></div>
                            </div>
                            <div>
                                <div className="text-truncate">I have the photos that you ordered last month, how
                                    would you like them sent to you?</div>
                                <div className="small text-gray-500">Jae Chun · 1d</div>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="images/undraw_profile_3.svg"
                                    alt="..." />
                                <div className="status-indicator bg-warning"></div>
                            </div>
                            <div>
                                <div className="text-truncate">Last month's report looks great, I am very happy with
                                    the progress so far, keep up the good work!</div>
                                <div className="small text-gray-500">Morgan Alvarez · 2d</div>
                            </div>
                        </a>
                        <a className="dropdown-item d-flex align-items-center" href="#">
                            <div className="dropdown-list-image mr-3">
                                <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                    alt="..." />
                                <div className="status-indicator bg-success"></div>
                            </div>
                            <div>
                                <div className="text-truncate">Am I a good boy? The reason I ask is because someone
                                    told me that people say this to all dogs, even if they aren't good...</div>
                                <div className="small text-gray-500">Chicken the Dog · 2w</div>
                            </div>
                        </a>
                        <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                    </div>
                </li> */}

                {/* <div className="topbar-divider d-none d-sm-block"></div> */}


                <li className="nav-item dropdown no-arrow">
                    <a className="nav-link dropdown-toggle" onClick={() => setShow(!show)} href="javascript:void(0)" id="userDropdown" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span className="mr-2 d-none d-lg-inline text-gray-600 small">Hello, {agent?.username}</span>
                        <img className="img-profile rounded-circle"
                            src="/images/undraw_profile.svg" />
                    </a>

                    <div className={"dropdown-menu dropdown-menu-right shadow animated--grow-in" +  (show ? " show" :"")}
                        aria-labelledby="userDropdown">
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                            Profile
                        </a>
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                            Settings
                        </a>
                        <a className="dropdown-item" href="#">
                            <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                            Activity Log
                        </a>
                        <div className="dropdown-divider"></div>
                        <Link onClick={()=> logoutAgent()} className="dropdown-item" data-toggle="modal" data-target="#logoutModal">
                            <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                            Logout
                        </Link>
                    </div>
                </li>

            </ul>

        </nav>
        {
            mobileMenu? 
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="mobile-menu">
            <div className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                <div className="sidebar-brand-icon" onClick={()=>setMobileMenu(!mobileMenu)}>
                    <i className="fas fa-times-circle"></i>
                </div>
            </div>

            
            <hr className="sidebar-divider my-0" />

            
            <li className={"nav-item" + ( location.pathname == '/' ? ' active' : '')}>
                <Link className="nav-link" to="/" onClick={()=> {setMobileMenu(!mobileMenu);{setOrderMenu(false);setCustomerMenu(false);}}}>
                    <i className="fas fa-fw fa-tachometer-alt"></i>
                    <span>Dashboard</span></Link>
            </li>

            {
                    admin_access ? 
                    <li className={"nav-item" + (  agent_paths.includes(location.pathname) ? ' active' : '')}>
                        <Link className={"nav-link"} to="/agents" onClick={()=>{setMobileMenu(!mobileMenu);{setOrderMenu(false);setCustomerMenu(false);}}}>
                            {/* <i className="fas fa-fw fa-wrench"></i> */}
                            <i className="fas fa-solid fa-user-secret"></i>
                            <span> Agents </span>
                        </Link>
                    </li>
                    : null
                }
                
                
            <li className={"nav-item" + ( location.pathname == '/products' ? ' active' : '')}>
                    <Link className="nav-link" to="/products" onClick={()=>{setMobileMenu(!mobileMenu);{setOrderMenu(false);setCustomerMenu(false);}}}>
                <i className="fas fa-fw fa-list"></i>
                    <span> Products </span>
                    </Link>
            </li>
                
                <li className={"nav-item" + (  customer_paths.includes(location.pathname) ? ' active' : '')}>
                    <a className={"nav-link" + (!customerMenu ? " collapsed"  :'')} onClick={(e) => { e.preventDefault(); {setCustomerMenu(!customerMenu);setOrderMenu(false);}}} href="" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                        {/* <i className="fas fa-fw fa-wrench"></i> */}
                        <i className="fas fa-solid fa-user"></i>
                        <span> Customers </span>
                    </a>
                    <div id="collapseUtilities" className={"collapse" + (customerMenu ? ' show' : '') } aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            {/* <h6 className="collapse-header">Custom Utilities:</h6> */}
                            <Link className="collapse-item" to="/add-customer" onClick={()=>setMobileMenu(!mobileMenu)}> Add New Customer </Link>
                            <Link className="collapse-item" to="/pending-application" onClick={()=>setMobileMenu(!mobileMenu)}> Pending Application </Link>
                            <Link className="collapse-item" to="/approved-customers" onClick={()=>setMobileMenu(!mobileMenu)}> Approved Customer </Link>
                            <Link className="collapse-item" to="/rejected-customers" onClick={()=>setMobileMenu(!mobileMenu)}> Rejected Customer </Link>
                        </div>
                    </div>
                </li>
                
                <li className={"nav-item" + (  order_paths.includes(location.pathname) || location.pathname.startsWith('/orders/manage/') ? ' active' : '')}>
                    <a className={"nav-link" + (!orderMenu ? " collapsed"  :'')} onClick={(e) => { e.preventDefault(); {setOrderMenu(!orderMenu);setCustomerMenu(false);}}} href="" data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
                        <i className="fas fa-solid fa-shopping-cart"></i>
                        <span> Order </span>
                    </a>
                    <div id="collapseUtilities" className={"collapse" + (orderMenu ? ' show' : '') } aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
                        <div className="bg-white py-2 collapse-inner rounded">
                            <Link className="collapse-item" to="/orders" onClick={()=>setMobileMenu(!mobileMenu)}> Orders </Link>
                            <Link className="collapse-item" to="/pending-orders" onClick={()=>setMobileMenu(!mobileMenu)}> Pending Orders </Link>
                            <Link className="collapse-item" to="/order-history" onClick={()=>setMobileMenu(!mobileMenu)}> History </Link>
                        </div>
                    </div>
                </li>
            </ul>
        :null
        }
      </>
    );
}

export default Navbar;