import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const retriveAgents = async(page =1,perPage=10) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/agents?page=${page}&perPage=${perPage}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}


const retriveSingleAgent = async(id) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/single-agent/${id}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const deleteAgent = async(id) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.delete(`/agent/agent-remove/${id}`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const createAgent = async(agent) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.post(`/agent/create-new-agent`,{...agent},{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const updateAgent = async(agent) => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.post(`/agent/update-agent`,{...agent},{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

const retriveAllRoles = async() => {
    let token =await localStorage.getItem('agent-token')
    let headers ={
        'Authorization' : `Bearer ${token}`
    }
    return new Promise(function (resolve, reject) {
        axios.get(`/agent/roles`,{headers: headers})
            .then((response) => {
                resolve(response)
            })
            .catch((error) => {
                reject(error)
            })
    });
}

export { retriveAgents, deleteAgent,retriveAllRoles, createAgent, retriveSingleAgent, updateAgent };