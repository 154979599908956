import { Routes, Route } from "react-router-dom"
import routes from "../routes/route.js";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import AppWrapper from "./AppWrapper";
import { useState } from "react";
import Home from "../components/Home/Home.jsx";

let is_authenticated = false;
const Index = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const [redirect,setRedirect] = useState('');

    useEffect(async () => {
        is_authenticated = await localStorage.getItem('agent_authenticated');
        // console.log('auth', is_authenticated);
        /* if(is_authenticated == null && location.pathname != '/login'){
            if(location.pathname == '/register')
                navigate('/register');
            else
                navigate('/login');
        } */
        
        if (is_authenticated == 1 && (location.pathname == '/login' || location.pathname == '/register')) {
            navigate('/');
        }
        return ()=>{
            
        }
    });

   /*  useEffect(()=>{
        console.log('redirect change',redirect);
        if(redirect){
            console.log('redirect value',redirect);
            navigate(redirect)
        }
    },[ redirect]); */

    return (
        <>
            
            <Routes>
                {
                    routes.map((route, i) => {
                        if (location.pathname == route.path && route.private === true) {
                            if (localStorage.getItem('agent_authenticated')) {
                                return <Route exact path={route.path} key={i} element={<AppWrapper> <route.page.component /> </AppWrapper>}> </Route>
                            } else {
                                // console.log("ELSE AI_AUTH");
                                // setRedirect('/login')
                                // navigate('/login');
                                window.location.href ='/login'
                            }
                        } else {
                            if(route.page.title == 'Login' || route.page.title == 'Register')
                                return <Route exact path={route.path} key={i} element={<route.page.component />}> </Route>
                            else
                                return <Route exact path={route.path} key={i} element={<AppWrapper> <route.page.component /> </AppWrapper>}> </Route>
                        }
                    })
                }
                <Route path="*" render={() => <h1>No Routes Match</h1>} > </Route>
            </Routes>
            
        </>
    );
}

export default Index;