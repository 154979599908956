const Footer = () =>{
    return (
        <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; <a href="https://n2nsolution.co/" target="_blank">N2N Solution</a> 2022</span>
                    </div>
                </div>
            </footer>
    );
}


export default Footer;