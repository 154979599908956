import React from "react";
import { Route } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Navbar from "../components/Header/Navbar";

const AppWrapper = ({ children }) => {
    return (
        <div id="wrapper">
            <Header></Header>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navbar />
                    {children}
                </div>

                <Footer></Footer>
            </div>
        </div>
    );
}

export default AppWrapper;